<template>
  <div class="card is-info product-card">
    <div v-if="redirecting">
      <div class="redirect-notice notification is-success">
        <button class="delete" v-on:click="redirecting = false"></button>
        You'll be automatically redirected within a few seconds to:<br/>
        <code>
          {{ link }}
        </code>
      </div>
    </div>
    <div class="card-content">
      <div class="content is-size-6">
        <div class="content columns">
          <div class="column">
            <h2>{{ title }}</h2>
            <p>
              Price: <b>{{ price }}</b><br/>
            </p>
            <p>
              <small>As an Amazon Associate we earn from qualifying purchases.</small><br/>
              <small><i>Link generated: {{ generatedAt }}</i></small>
            </p>

          </div>
          <div class="column is-one-fifth">
            <div class="tag is-success is-small">
              Stock available!
            </div>
          </div>
        </div>

        <div class="has-text-right is-size-7 is-bold"><b><i>Listing from {{ retailer }}</i></b></div>
      </div>
    </div>

    <div class="card-footer">
      <a :href="link" target="_blank" class="card-footer-item is-link has-text-weight-bold">Buy now &rarr; </a>
    </div>
  </div>

</template>

<script>
const automaticRedirectWarningMillis = 5000;
const automaticRedirectMillis = 3000;
const pako = require("pako");

export default {
  name: 'Link',
  data() {
    return {
      redirecting: false,
      title: "Loading..",
      price: "N/A",
      link: "N/A",
      generatedAt: "N/A",
      retailer: "N/A"
    };
  },
  mounted() {
    try {
      let linkMetadata = parseLinkMetadata();

      this.title = linkMetadata["Title"];
      this.price = linkMetadata["Price"];
      this.link = linkMetadata["Url"];
      this.retailer = "Amazon";
      this.generatedAt = new Date(linkMetadata["Timestamp"] * 1000).toLocaleString();

      setTimeout(() => {
        this.redirecting = true;

        setTimeout(() => {
          if (!this.redirecting) {
            return;
          }

          window.location.href = this.link;
        }, automaticRedirectMillis);
      }, automaticRedirectWarningMillis);
    } catch (e) {
      window.location.href = "https://ctrlaltstock.com/?invalid-link"
    }
  }
}

function parseLinkMetadata() {
  let location = window.location;

  let decodedMetadata = atob(decodeURIComponent(location.pathname.substr(1)));
  let linkMetadataRaw = decodedMetadata.split('').map(function (x) {
    return x.charCodeAt(0);
  });

  let linkMetadataBinary = new Uint8Array(linkMetadataRaw);
  let decompressedMetadata = pako.inflate(linkMetadataBinary, {to: "string"})
  return JSON.parse(decompressedMetadata);
}

</script>

<style scoped>

.redirect-notice {
  width: 60vw;
}

a {
  color: #42b983;
}

.product-card {
  max-width: 60vw;
  margin: auto;
  position: relative;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.3), 0 0 0 1px rgba(10, 10, 10, 0.1);
}


@media only screen and (max-width: 600px) {
  .product-card {
    width: 100vw;
    max-width: 100vw;
    height: 100%;
  }

  .redirect-notice {
    width: 100vw;
  }
}

</style>
