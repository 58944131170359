<template>
  <div id="app">
    <div id="ctrlaltstock">
      <div id="header">
        <div class="logo"><a href="https://ctrlaltstock.com"><img alt="CtrlAltStock Logo"
                                                                  src="./assets/logo-light.png"></a>
        </div>
      </div>

      <Link/>
    </div>
    <footer>&copy; CtrlAltStock 2021 - <a href="https://ctrlaltstock.com/privacy-policy">Privacy Policy</a> | <a
        href="https://ctrlaltstock.com/terms-and-conditions">Terms & Conditions</a></footer>
  </div>
</template>

<script>
import 'bulma/css/bulma.css';

import Link from './components/Link.vue'

export default {
  name: 'CtrlAltStock.Link',
  components: {
    Link
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#ctrlaltstock {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  flex: 1;
}

#header {
  background: #2c3e50;
  background: -webkit-linear-gradient(top right, #132f3d 0%, #132f3d 0, #75967f 100%, #75967f 0);
  height: 20vh;
  margin-bottom: 10vh;
  display: flex;
  align-items: center;
}

.logo {
  margin: auto;
  width: 310px;
}

footer {
  padding: 10px;
  background: rgb(16, 33, 41);
  background: linear-gradient(90deg, rgba(16, 33, 41, 1) 4%, rgba(45, 45, 45, 1) 93%);
  color: #fff;
  text-align: center;

}

footer > a {
  color: #fff;
}
footer > a:hover {
  color: #cccccc;
}
</style>
